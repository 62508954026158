<!--
 * @Author: xx
 * @Date: 2022-09-20 15:04:50
 * @Description: 
 * @FilePath: \nedd\src\layout\index.vue
-->
<template>
  <div id="layout">
    <div class="headerBox">
      <Header></Header>
    </div>
    <div class="layout-content">
      <div class="app_main">
        <router-view />
      </div>
    </div>
  </div>
</template>
<script>
import Header from "./Header";
export default {
  name: "Layout",
  data() {
    return {
      
    };
  },
  components: {
    Header,
  },
  methods: {

  },
  mounted() {

  },
};
</script>
<style>
.con .el-menu-item {
    height: 36px;
}
</style>
<style lang="scss" scoped>
@import "./style/index.scss";
</style>
