<!--
 * @Author: xx
 * @Date: 2022-12-01 17:06:02
 * @Description: 
 * @FilePath: \nedd\src\layout\Header.vue
-->
<template>
  <div id="header_wrapper" class="clearFix">
    <div class="inner">
      <el-row :gutter="40">
        <el-col :span="5">
          <div class="grid-content">
            <img src="../assets/images/img/nav_logo.png" class="icon" alt="" />
          </div>
        </el-col>
        <el-col :span="14">
          <div class="grid-content">
            <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
              <el-menu-item index="1">
                <router-link to="/index">首页</router-link>
              </el-menu-item>
              <el-menu-item index="2">
                <router-link to="/products">产品服务</router-link>
              </el-menu-item>
              <!-- <el-submenu index="2">
                <template slot="title">产品与服务</template>
                <el-menu-item index="2-1"><router-link to="/product">产品中心</router-link></el-menu-item>
                <el-menu-item index="2-2"><router-link to="/technicalService">技术服务</router-link></el-menu-item>
              </el-submenu> -->
              <el-submenu index="3">
                <template slot="title">关于网华</template>
                <el-menu-item index="3-1"><router-link to="/companyProfile">公司简介</router-link></el-menu-item>
                <el-menu-item index="3-2"><router-link to="/honoraryQualifications">荣誉资质</router-link></el-menu-item>
                <el-menu-item index="3-3"><router-link to="/scyj">市场业绩</router-link></el-menu-item>
                <el-menu-item index="3-4"><router-link to="/qyvideo">企业视频</router-link></el-menu-item>
              </el-submenu>
              
            </el-menu>
          </div>
        </el-col>
        <el-col :span="5">
          <div class="grid-content">
            <router-link to="/link">
              <span style="display: flex;align-items: center;">
                <img src="../assets/images/img/nav_icon_phone.png">
                <el-link type="primary">联系我们</el-link>
              </span>
            </router-link>
            <el-link style="margin-left: 2em;"type="primary" href="./data/ProductBrochure.pdf" download="产品手册.pdf" target="_blank"><i class="el-icon-download"></i>下载产品手册</el-link>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Header",
  data() {
    return {
      activeIndex: "1",
    };
  },
  methods: {
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
  },
};
</script>

<style lang="scss" scoped>
#header_wrapper {
  height: 40pt;
  position: relative;
  display: flex;

  .icon {}

  .inner {
    width: 80%;
    margin: auto;
    height: 28px;

    .el-row {
      margin-bottom: 20px;
      height: 100%;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .el-col {
      border-radius: 4px;
      height: 100%;
    }

    .bg-purple-dark {
      background: #99a9bf;
    }

    .bg-purple {
      background: #d3dce6;
    }

    .bg-purple-light {
      background: #e5e9f2;
    }

    .grid-content {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      cursor: pointer;
    }

    .row-bg {
      padding: 10px 0;
      background-color: #f9fafc;
    }

    .el-menu.el-menu--horizontal {
      height: 100%;
    }

    .el-menu.el-menu--horizontal li {
      height: 100%;
      line-height: inherit;
    }

  }
}
</style>
<style lang="scss">
.el-menu--horizontal>.el-submenu .el-submenu__title {
  height: 100% !important;
  line-height: inherit !important;
}
</style>
