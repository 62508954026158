/*
 * @Author: xx
 * @Date: 2022-09-20 15:04:50
 * @Description: 
 * @FilePath: \nedd\src\router\index.js
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import Header from '@/layout'

Vue.use(VueRouter)

const routes = [
  
  {
    path: '/',
    name: 'index',
    redirect: '/index',
    component: Header,
    children: [
      {
        path: '/index',
        name:'index',
        component: () => import('@/views/index/index.vue')
      },
      {
        path: '/product',
        name:'product',
        component: () => import('@/views/product/index.vue')
      },
      {
        path: '/products',
        name:'products',
        component: () => import('@/views/products/index.vue')
      },
      {
        path: '/technicalService',
        name:'technicalService',
        component: () => import('@/views/technicalService/index.vue')
      },
      {
        path: '/companyProfile',
        name:'companyProfile',
        component: () => import('@/views/companyProfile/index.vue')
      },
      {
        path: '/honoraryQualifications',
        name:'honoraryQualifications',
        component: () => import('@/views/honoraryQualifications/index.vue')
      },
      {
        path: '/link',
        name:'link',
        component: () => import('@/views/link/index.vue')
      },  
      {
        path: '/scyj',
        name:'scyj',
        component: () => import('@/views/scyj/index.vue')
      },  
      {
        path: '/qyvideo',
        name:'qyvideo',
        component: () => import('@/views/qyvideo/index.vue')
      },                       
    ]

  },
]

const router = new VueRouter({
  routes,
  base: process.env.BASE_URL,
})

export default router
